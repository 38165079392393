// import Aromanize from 'aromanize';

export default class Korean {
    static convertRomanisationToKorean(input, answer) {
      // need to use `answer`. Sometimes ng is ㅇ, and sometimes it's ㄴㄱ.

      // suggested implementation:
      // 1. convert answer to latin
      // 2. go from the beginning and convert the coinciding letters into hangul.
      // 3. convert the rest of the input into hangul?

      return romanToHangul(input);
    }    
}

// written by chatgpt
// Hangul components
const initialConsonants = ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];
const vowels = ["ㅏ", "ㅐ", "ㅑ", "ㅒ", "ㅓ", "ㅔ", "ㅕ", "ㅖ", "ㅗ", "ㅘ", "ㅙ", "ㅚ", "ㅛ", "ㅜ", "ㅝ", "ㅞ", "ㅟ", "ㅠ", "ㅡ", "ㅢ", "ㅣ"];
const finalConsonants = ["", "ㄱ", "ㄲ", "ㄱㅅ", "ㄴ", "ㄴㅈ", "ㄴㅎ", "ㄷ", "ㄹ", "ㄹㄱ", "ㄹㅁ", "ㄹㅂ", "ㄹㅅ", "ㄹㅌ", "ㄹㅍ", "ㄹㅎ", "ㅁ", "ㅂ", "ㅂㅅ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];

// Mappings
const hangulToLatin = {
  "ㄱ": "g",
  "ㄲ": "kk",
  "ㅋ": "k",
  "ㄴ": "n",
  "ㄷ": "d",
  "ㅌ": "t",
  "ㄸ": "tt",
  "ㄹ": "r",
  "ㅁ": "m",
  "ㅂ": "b",
  "ㅃ": "pp",
  "ㅅ": "s",
  "ㅆ": "ss",
  "ㅇ": "ng",
  "ㅈ": "j",
  "ㅉ": "jj",
  "ㅊ": "ch",
  "ㅍ": "p",
  "ㅎ": "h",
  "ㅙ": "wae",
  "ㅝ": "weo",
  "ㅒ": "yae",
  "ㅕ": "yeo",
  "ㅐ": "ae",
  "ㅑ": "ya",
  "ㅖ": "ye",
  "ㅓ": "eo",
  "ㅘ": "wa",
  "ㅚ": "oe",
  "ㅛ": "yo",
  "ㅞ": "we",
  "ㅟ": "wi",
  "ㅠ": "yu",
  "ㅡ": "eu",
  "ㅢ": "ui",
  "ㅏ": "a",
  "ㅔ": "e",
  "ㅗ": "o",
  "ㅜ": "u",
  "ㅣ": "i"
};

const latinToHangul = {
  "kk": "ㄲ",
  "tt": "ㄸ",
  "pp": "ㅃ",
  "ss": "ㅆ",
  "jj": "ㅉ",
  "ng": "ㅇ",
  "ch": "ㅊ",
  "ae": "ㅐ",
  "yae": "ㅒ",
  "yeo": "ㅕ",
  "wae": "ㅙ",
  "weo": "ㅝ",
  "ya": "ㅑ",
  "eo": "ㅓ",
  "ye": "ㅖ",
  "wa": "ㅘ",
  "oe": "ㅚ",
  "yo": "ㅛ",
  "we": "ㅞ",
  "wi": "ㅟ",
  "yu": "ㅠ",
  "eu": "ㅡ",
  "ui": "ㅢ",
  "a": "ㅏ",
  "e": "ㅔ",
  "o": "ㅗ",
  "u": "ㅜ",
  "i": "ㅣ",
  "g": "ㄱ",
  "n": "ㄴ",
  "d": "ㄷ",
  "r": "ㄹ",
  "m": "ㅁ",
  "b": "ㅂ",
  "s": "ㅅ",
  "j": "ㅈ",
  "k": "ㅋ",
  "t": "ㅌ",
  "p": "ㅍ",
  "h": "ㅎ"
};
/*
const hangulSequenceReplacements = {
  "ㄴㄱ": "ㅇ", "ㄱㄱ": "ㄲ", // Add other necessary replacements here
};*/

const latinToHangulEntries = Object.entries(latinToHangul).sort((a, b) => b[0].length - a[0].length);

function decomposeHangul(syllable) {
  const code = syllable.charCodeAt(0);
  if (code < 0xAC00 || code > 0xD7A3) return syllable;
  const syllableIndex = code - 0xAC00;
  const codaIndex = syllableIndex % 28;
  const nucleusIndex = ((syllableIndex - codaIndex) / 28) % 21;
  const onsetIndex = (((syllableIndex - codaIndex) / 28) - nucleusIndex) / 21;
  const onset = initialConsonants[onsetIndex];
  const nucleus = vowels[nucleusIndex];
  const coda = finalConsonants[codaIndex];
  return coda ? [onset, nucleus, coda] : [onset, nucleus];
}

function breakSyllables(input) {
  const result = [];
  for (let char of input) {
    const letters = decomposeHangul(char);
    if (typeof letters === "string") {
      result.push(char);
    } else {
      result.push(...letters);
    }
  }
  return result;
}

  function hangulLettersToLatin(letters) {
    let result = "";
    for (let letter of letters) {
      result += hangulToLatin[letter] || letter;
    }
    return result;
  }

  function latinToHangulLetters(latinStr) {
    const result = [];
    let i = 0;
    while (i < latinStr.length) {
      let matched = false;
      for (let [key, value] of latinToHangulEntries) {
        if (latinStr.substr(i, key.length) === key) {
          result.push(value);
          i += key.length;
          matched = true;
          break;
        }
      }
      if (!matched) {
				//result.push(latinStr.substr(i, 1));
				i++; // Skip unmapped characters
			}
    }
    return result;
  }
/*
  function replaceDoubleSymbols(letters) {
    const result = [];
    let i = 0;
    while (i < letters.length) {
      const seq = letters[i] + (letters[i + 1] || "");
      if (hangulSequenceReplacements[seq]) {
        result.push(hangulSequenceReplacements[seq]);
        i += 2;
      } else {
        result.push(letters[i]);
        i++;
      }
    }
    return result;
  } */

  function composeHangul(onset, nucleus, coda = "") {
    const onsetIndex = initialConsonants.indexOf(onset);
    const nucleusIndex = vowels.indexOf(nucleus);
    const codaIndex = finalConsonants.indexOf(coda);
    if (onsetIndex === -1 || nucleusIndex === -1 || codaIndex === -1) {
      return onset + nucleus + coda; // Return as is if invalid
    }
    const syllableCode = 0xAC00 + (onsetIndex * 21 * 28) + (nucleusIndex * 28) + codaIndex;
    return String.fromCharCode(syllableCode);
  }

  function combineLettersIntoSyllables(letters) {
    let result = "";
    let i = 0;
    while (i < letters.length) {
      let onset = letters[i];
      let nucleus = letters[i + 1];
      let coda = "";
      if (initialConsonants.includes(onset) && vowels.includes(nucleus)) {
        i += 2;
        if (i < letters.length && finalConsonants.includes(letters[i])) {
          // Check if next consonant is not onset of a new syllable
          if (i + 1 < letters.length && vowels.includes(letters[i + 1])) {
            // Next consonant is onset of next syllable
          } else {
            coda = letters[i];
            i++;
          }
        }
        result += composeHangul(onset, nucleus, coda);
      } else {
        result += onset;
        i++;
      }
    }
    return result;
  }

function romanToHangul(input) {
  const letters = breakSyllables(input);
  console.log(letters);
  const latinStr = hangulLettersToLatin(letters);
  let hangulLetters = latinToHangulLetters(latinStr);
  //hangulLetters = replaceDoubleSymbols(hangulLetters);
  return combineLettersIntoSyllables(hangulLetters);
}
