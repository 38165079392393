import {
	courseGet10Training,
	getTrainingWordsGuest,
	pocGetTrainingAi,
	complain,
	updateWordLastDate,
	courseGet10TrainingPackageScenario,
} from '@/lib/api';
import { isChinese, convertPinyinToChinese, chineseToPinyinMap, convertChineseSentenceToPinyin } from '../lib/languages/chinese';
import Korean from '../lib/languages/korean';
import Japanese from '../lib/languages/japanese';
import polyGreekNormalize from '@/lib/languages/poly-greek-normalize';

// возвращает количество символов, совпадающих с начала строки
function howManySame(a, b) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return i;
    }
    return a.length;
}

// todo: вместо этого сделать наследование классов типа class ChineseModel extends GeneralModel
const supportLanguage = {
	isChinese(card) {
		return card?.wordLangSource === 4
	},
	isJapaneseWord(card) {
		return card?.wordLangSource === 13;
	},
	isKoreanWord(card) {
		return card?.wordLangSource === 14;
	},

	preSetAnswer(card, value) {
		if (supportLanguage.isChinese(card)) {
			return convertPinyinToChinese(value, card.wordValue, card.wordLemma)
		}
		if (supportLanguage.isJapaneseWord(card)) {
			return Japanese.convertRomanisationToJapanese(value, card.wordValue, card.wordTranslation.split(' | ')[0]);
		}
		if (supportLanguage.isKoreanWord(card)) {
			return Korean.convertRomanisationToKorean(value, card.wordValue);
		}
		return value;
	},

	fixLastCharForHelp(card, value, lastChar) {
		// объяснение — там, где эта функция вызывается
		// lastChar = last char of value (lastHelpedChar actually)
		if (supportLanguage.isChinese(card) && lastChar === ' ') {
			return convertPinyinToChinese(value, card.wordValue, card.wordLemma);
		}
		return value;
	},

	helpForChinese(state) {
		let current = state.answerToSend.toLowerCase();
		const card = state.data[state.currentCardIndex];
		const hanziMap = chineseToPinyinMap(card.answer[1], card.wordLemma);
		const chunks = card.answer[1].split('').map((char, i) => ({
			hanzi: char,
			pinyin: hanziMap[char],
		}));
		
		current = current.replace(/\s/gi, '');

		let currentChunkIndex = 0;
		for (let i = 0; i < current.length; i++) {
			const currentChunk = chunks[currentChunkIndex];
			if (isChinese(current[i]) && currentChunk.hanzi === current[i]) {
				currentChunkIndex++;
			} else if (current[i].match(/[a-zA-Z0-9]/gi)) {
				if (current.slice(i).indexOf(currentChunk.pinyin) === 0) {
					// jump to checking next chunk
					i += currentChunk.pinyin.length - 1;
					currentChunkIndex++;
				} else {
					// we started entering pinyin
					const howMany = howManySame(current.slice(i), currentChunk.pinyin);
					const sliced = current.substr(0, i + howMany);
					if (sliced === state.answerToSend.toLowerCase()) {
						// if everything is correct, we just add another part of pinyin
						// for the current chunk
						state.answerToSend = sliced + currentChunk.pinyin[howMany];
					} else {
						// if something's incorrect, we cut until it's correct
						state.answerToSend = sliced;
					}
					return;
				}
			} else {
				// it's not hanzi neither pinyin - cut until here
				state.answerToSend = current.slice(0, i);
				return;
			}
		}

		if (chunks[currentChunkIndex]) {
			state.answerToSend += chunks[currentChunkIndex].pinyin[0];
		}
		state.answerToSend = convertPinyinToChinese(state.answerToSend, card.wordValue, card.wordLemma);
	}
};

// TODO: нажатие на любую из кнопок (AI, подсказка, етс) — для слов > 8
// отменяет повторение, откладывая его ненадолго (на неделю, скажем)
// т.к. это именно те слова, которые должны уже всплывать сами

export default {
	namespaced: true,

	state: () => ({
		loading: true,
		data: null,
		currentCardIndex: null,
		answerToSend: null,
		complained: false,
		wordsRepeated: null,
		textFieldType: null,
		answerClicked: false,
		finishedTraining: false,
	}),

	getters: {
		courseIsEmpty: (state) => !state.loading && state.data && state.data.length === 0,

		currentCard: (state) => state.data ? state.data[state.currentCardIndex] : null,

		isEnglishWord: (state) => state.currentCard?.wordLangSource === 0,
		isChineseWord: (state) => state.currentCard?.wordLangSource === 4,
		isJapaneseWord: (state) => state.currentCard?.wordLangSource === 13,
		isKoreanWord: (state) => state.currentCard?.wordLangSource === 14,
		// todo: use langSource
		isGreekWord: (state, getters) => getters.cardAnswer.match(/[Α-Ωα-ωίϊΐόάέύϋΰήώ]/),

		cardQuestion: (state, getters) => getters.currentCard.question.join(''),
		cardAnswer: (state, getters) => getters.currentCard.answer[1],

		cardAnswerPre: (state, getters) => {
			const card = getters.currentCard;
			if (getters.isChineseWord && state.answerClicked) {
				try {
					return convertChineseSentenceToPinyin(card.answer[0]); 
				} catch (e) {}
			}
			return card.answer[0];
		},

		cardAnswerPost: (state, getters) => {
			const card = getters.currentCard;
			if (getters.isChineseWord && state.answerClicked) {
				try {
					return convertChineseSentenceToPinyin(card.answer[2]);
				} catch (e) {}
			}
			return card.answer[2];
		},

		correct: (state, getters) => {
			let userAnswer = state.answerToSend.trim().toLowerCase().split('-').join(' ');
			let cardAnswer = getters.cardAnswer.trim().toLowerCase().split('-').join(' ');
			if (getters.isGreekWord) {
				userAnswer = polyGreekNormalize(userAnswer);
				cardAnswer = polyGreekNormalize(cardAnswer);
			}
			return userAnswer === cardAnswer;
		},
	},

	actions: {
		// actions describe updates
		// mutations are called only by actions
		// actions should be called as verbs
		loadTraining: async function({ commit }, { courseId, userLoggedIn, scenario }) {
			commit('LOADING_STARTED');
			if (userLoggedIn) {
				const data = scenario
					? await courseGet10TrainingPackageScenario(courseId, scenario)
					: await courseGet10Training(courseId);
				commit('LOADING_FINISHED', data);
			} else {
				const data =  await getTrainingWordsGuest(courseId);
				commit('LOADING_FINISHED', data);
			}
		},

		loadAiTraining: async function({ commit }) {
			const value = window.prompt(
				'Target Language : Native Language; word:translation:part of speech; word:translation:part of speech',
				''
			);
			const [langs, ...rawWords] = value.split(';').map(x => x.trim());
			const [languageTarget, languageNative] = langs.split(':').map(x => x.trim());
			const words = rawWords.map(word => {
				word = word.split(':');
				return {
					word: word[0].trim(),
					translation: word[1].trim(),
					pos: word[2].trim(),
				}
			})

			commit('LOADING_STARTED');
			commit('LOADING_FINISHED', await pocGetTrainingAi(words, languageNative, languageTarget));
		},

		loadPicturesTraining: async function({ state, commit }) {
			if (state.loading || !state.data) {
				throw new Error('Pictures training loading requires loading to be finished');
			}

			const per_page = 3;
			const { createClient } = require('pexels');
			const client = createClient('ri' + 'WB20ANo' + 'q05h3pYvO' + 'RdFD0pH' +
				'DU22MPC' + 'cSgIxba' + 'VzEohHjj' + 'vVL5aotmm');

			 // wordId => { q: wordText / description, cards: Card[] }
			const wordsKeys = {};
			state.data.map(card => {
				let wordText;
				if (card.wordLangDest === 1 && card.wordLangSource === 0) {
					wordText = card.wordText.trim();
				} else {
					const description = card.wordTranslation.split(' | ').at(-1);
					wordText = description.split(',')[0].trim();
				}

				if (!wordsKeys[card.wordId]) {
					wordsKeys[card.wordId] = { q: wordText, cards: [] };
				}
				wordsKeys[card.wordId].cards.push(card);
			});

			const entries = Object.entries(wordsKeys);

			// NOTE: 'pictures' doesn't get observable, probably because it's not set in mutation
			// but we don't care about it
			await Promise.all(
				entries.map(async ([wordId, wordData]) => {
					const photos = await client.photos.search({ query: wordData.q, per_page });
					// populate cards with photos
					wordData.cards.forEach((card, i) => {
						card.picture = photos.photos[i];
					});
				})
			);

			console.log('pictures loaded', state.data);
		},

		closeDescriptionMode({ commit }) {
			commit('CURRENT_CARD_NOT_NEW');
		},

		updateAnswerToSend({ commit, state, getters }, value) {
			commit('CURRENT_CARD_SET_ANSWER_TO_SEND', value);

			// when you start typing, we remove the "wrong" state
			if (state.textFieldType === 'wrong') {
				commit('TEXTFIELD_TYPE_WAS_WRONG');
			}

			// we highlight correct answer if you fixed your mistake
			if (getters.correct && (state.textFieldType === 'wrong' || state.textFieldType === 'was-wrong')) {
				commit('TEXTFIELD_TYPE_IS_WOW_CORRECT'); 
			} else if (state.textFieldType === 'wow-correct' && !getters.correct) {
				commit('TEXTFIELD_TYPE_RESET');
			}
		},

		attemptNextCard({ state, commit, getters }, opts) {
			const ignoreCorrect = opts && opts.ignoreCorrect;
			if (getters.correct || ignoreCorrect) {
				commit('WORD_PRACTICED_3_TIMES');
				if (state.currentCardIndex + 1 === state.data.length) {
					commit('FINISHED_TRAINING');
				} else {
					commit('NEXT_CARD');
					commit('TEXTFIELD_TYPE_RESET');
				}
			} else {
				commit('TEXTFIELD_TYPE_IS_WRONG');
			}
		}, 

		markCardAsKnown({ store, commit, getters }) {
			commit('CURRENT_CARD_KNOWN');
			if (store.currentCardIndex === store.data.length) {
				commit('FINISHED_TRAINING');
			}
		},

		answerClick({ commit }) {
			commit('ANSWER_CLICKED');
		},

		help({ commit }) {
			commit('CURRENT_CARD_HELP');
		},

		async complainCurrentCard({ getters, commit }, { type, text }) {
			const card = getters.currentCard;
			await complain({
				sentenceId: card.sentenceId,
				wordId: card.wordId,
				// todo: перенести в конфиг
				type: ['incorrect-word-translation', 'incorrect-sentence-translation', 'incorrect-sentence', 'other'].indexOf(type),
				text
			});
			commit('COMPLAINED');
		},
	},

	mutations: {
		// should be named to describe things that happened in state
		// LOADING_STARTED
		LOADING_STARTED(state) {
			state.loading = true;
			state.data = null;
			state.currentCardIndex = null;
			state.answerToSend = null;
			state.answerClicked = false;
			state.wordsRepeated = {};
			state.complained = false;
			state.textFieldType = null;
			state.finishedTraining = false;
		},

		LOADING_FINISHED(state, data) {
			state.loading = false;
			state.data = data;
			state.currentCardIndex = 0;
			state.answerToSend = '';
			state.wordsRepeated = {};
			for (var i = 0; i < data.length; i++) {
				if (state.wordsRepeated[data[i].wordId]) {
					data[i].wordIsNew = false;
				}

				if (!state.wordsRepeated[data[i].wordId]) {
					state.wordsRepeated[data[i].wordId] = {
						total: 0,
						completed: 0,
					};
				}
				state.wordsRepeated[data[i].wordId].total++;
				// data[i].wordIsNew = true;
			}
		},

		// NEXT_CARD
		NEXT_CARD(state) {
			state.currentCardIndex++;
			state.answerToSend = '';
			state.complained = false;

			// if state.currentCardIndex > state.data.length
		},

		WORD_PRACTICED_3_TIMES(state) {
			const wordId = state.data[state.currentCardIndex].wordId;
			if (++state.wordsRepeated[wordId].completed >= Math.min(3, state.wordsRepeated[wordId].total)) {
				updateWordLastDate(wordId, false);
			}
		},

		CURRENT_CARD_NOT_NEW(state) {
			state.data[state.currentCardIndex].wordIsNew = false;
		},

		CURRENT_CARD_SET_ANSWER_TO_SEND(state, value) {
			state.answerToSend = supportLanguage.preSetAnswer(
				state.data[state.currentCardIndex],
				value
			);
		},

		COMPLAINED(state) {
			state.complained = true;
		},

		CURRENT_CARD_KNOWN(state) {
			const wordId = state.data[state.currentCardIndex].wordId;
			updateWordLastDate(wordId, true);
			state.data = state.data.filter(word => word.wordId !== wordId);
		},

		// move all this logic into action 
		CURRENT_CARD_HELP(state) {
			let current = state.answerToSend.toLowerCase();
			const answer = state.data[state.currentCardIndex].answer[1].toLowerCase();

			if (isChinese(answer)) {
				return supportLanguage.helpForChinese(state);
			}

			if (current === answer) {
				return;
			}
			// right answer + smth else (eg, 'finely' instead of 'fine')
			if (current.indexOf(answer) === 0) {
				state.answerToSend = state.answerToSend.slice(0, answer.length);
				return;
			}

			let i = 0;

			if (current.length > answer.length) {
				current = current.slice(0, answer.length);
			}

			// find i for which they diverge
			for (; i < answer.length; i++) {
				if (current[i] !== answer[i]) {
					break;
				}
			}

			// if the whole word is wrong, also hint the first letter
			if (current[i] && i !== 0) {
				current = current.slice(0, i);
			} else {
				current = current.slice(0, i) + answer[i];
			}

			state.answerToSend = current;
		},

		ANSWER_CLICKED: (state) => state.answerClicked = !state.answerClicked,

		TEXTFIELD_TYPE_RESET: (state) => state.textFieldType = null,
		TEXTFIELD_TYPE_IS_WRONG: (state) => state.textFieldType = 'wrong',
		// "was wrong" isn't visually indicated, but is used to set "wow-correct"
		// so this way we highlight that you fixed your mistake
		TEXTFIELD_TYPE_WAS_WRONG: (state) => state.textFieldType = 'was-wrong',
		TEXTFIELD_TYPE_IS_WOW_CORRECT: (state) => state.textFieldType = 'wow-correct',

		FINISHED_TRAINING(state) {
			state.finishedTraining = true;
		},
	},
};
