export default class Japanese {
    static isHiragana(str) {
        return /^[\u3040-\u309F]+$/.test(str);
    }

    static isKatakana(str) {
        return /^[\u30A0-\u30FF]+$/.test(str);
    }

    /** Will mostly return true for Chinese characters as well */
    static isKanji(str, extended = true) {
        return (extended ? /^[\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF]+$/ : /^[\u4E00-\u9FAF]+$/).test(str);
    }

    static convertRomanisationToJapanese(input, answer, hiragana) {
        // we expect hiragana to be splitted by spaces if there's > 1 character
        // e.g. "猫猫" = "ねこ ねこ"
        const hiraganaSplitted = hiragana.split(' ');
        // we get [{character: '猫', hiragana: 'ねこ'}, {character: '猫', hiragana: 'ねこ'}]
        // keep it an array because the same symbol may have different hiragana
        const charactersMap = answer.split('').map((character, i) => ({
            character,
            hiragana: hiraganaSplitted[i],
        }));

        // transform input into hiragana
        // need to use `fixInvalidNCombinations`, because you might be inputting something like ne → ん → ね
        const transformIntoHiragana = str => charactersMap.reduce((result, { character, hiragana }) => {
            return result.split(character).join(hiragana);
        }, fixInvalidNCombinations(romajiToHiragana(str)));

        let transformedInput = transformIntoHiragana(input);
        // const transformedAnswer = transformIntoHiragana(answer);

        let processedStringLength = 0;
        for (let i = 0; i < answer.length; i++) {
            const currentCharacter = answer[i];
            const currentCharacterHiragana = charactersMap[i].hiragana;
            
            const processedPartOfStringBefore = transformedInput.slice(0, processedStringLength);
            const partBeingProcessed = transformedInput.slice(processedStringLength);
            if (partBeingProcessed.indexOf(currentCharacterHiragana) === 0) {
                transformedInput = processedPartOfStringBefore + currentCharacter + partBeingProcessed.slice(currentCharacterHiragana.length);
                processedStringLength += currentCharacter.length;
            }
        }
        return transformedInput;
    }
}

// written by chatgpt
function romajiToHiragana(romaji) {
    const romajiToHiraganaMap = {
        "a": "あ", "i": "い", "u": "う", "e": "え", "o": "お",
        "ka": "か", "ki": "き", "ku": "く", "ke": "け", "ko": "こ",
        "sa": "さ", "shi": "し", "su": "す", "se": "せ", "so": "そ",
        "ta": "た", "chi": "ち", "tsu": "つ", "te": "て", "to": "と",
        "na": "な", "ni": "に", "nu": "ぬ", "ne": "ね", "no": "の",
        "ha": "は", "hi": "ひ", "fu": "ふ", "he": "へ", "ho": "ほ",
        "ma": "ま", "mi": "み", "mu": "む", "me": "め", "mo": "も",
        "ya": "や", "yu": "ゆ", "yo": "よ",
        "ra": "ら", "ri": "り", "ru": "る", "re": "れ", "ro": "ろ",
        "wa": "わ", "wo": "を", "n": "ん",
        "ga": "が", "gi": "ぎ", "gu": "ぐ", "ge": "げ", "go": "ご",
        "za": "ざ", "ji": "じ", "zu": "ず", "ze": "ぜ", "zo": "ぞ",
        "da": "だ", "de": "で", "do": "ど",
        "ba": "ば", "bi": "び", "bu": "ぶ", "be": "べ", "bo": "ぼ",
        "pa": "ぱ", "pi": "ぴ", "pu": "ぷ", "pe": "ぺ", "po": "ぽ",
        "kya": "きゃ", "kyu": "きゅ", "kyo": "きょ",
        "sha": "しゃ", "shu": "しゅ", "sho": "しょ",
        "cha": "ちゃ", "chu": "ちゅ", "cho": "ちょ",
        "nya": "にゃ", "nyu": "にゅ", "nyo": "にょ",
        "hya": "ひゃ", "hyu": "ひゅ", "hyo": "ひょ",
        "mya": "みゃ", "myu": "みゅ", "myo": "みょ",
        "rya": "りゃ", "ryu": "りゅ", "ryo": "りょ",
        "gya": "ぎゃ", "gyu": "ぎゅ", "gyo": "ぎょ",
        "ja": "じゃ", "ju": "じゅ", "jo": "じょ",
        "bya": "びゃ", "byu": "びゅ", "byo": "びょ",
        "pya": "ぴゃ", "pyu": "ぴゅ", "pyo": "ぴょ"
    };

    // Normalize input
    romaji = romaji.toLowerCase();

    // Match and replace using longest-first mapping
    let result = "";
    while (romaji.length > 0) {
        let matched = false;

        // Match the longest possible substring
        for (const [key, value] of Object.entries(romajiToHiraganaMap).sort((a, b) => b[0].length - a[0].length)) {
            if (romaji.startsWith(key)) {
                result += value;
                romaji = romaji.slice(key.length);
                matched = true;
                break;
            }
        }

        // Handle unmatched cases (e.g., invalid input)
        if (!matched) {
            result += romaji[0]; // Append the unmatched character as-is
            romaji = romaji.slice(1);
        }
    }

    return result;
}

// also written by chatgpt
function fixInvalidNCombinations(input) {
// chatgpt said that such combinations may be valid in case of glottal stop
// (but incredibly rare). we ignore that for now
// てんい (ten'i) – This includes a glottal pause between ん and い.
// こんいん (kon'in) – Glottal stop inserted between ん and い.

    const invalidNReplacements = {
        "んあ": "な",
        "んい": "に",
        "んう": "ぬ",
        "んえ": "ね",
        "んお": "の",
        "んや": "にゃ",
        "んゆ": "にゅ",
        "んよ": "にょ"
    };

    let result = input;

    for (const [invalid, valid] of Object.entries(invalidNReplacements)) {
        result = result.split(invalid).join(valid);
    }

    return result;
}

// written by chatgpt
function japaneseToRomaji(input) {
    const kanaToRomajiMap = {
        "あ": "a", "い": "i", "う": "u", "え": "e", "お": "o",
        "か": "ka", "き": "ki", "く": "ku", "け": "ke", "こ": "ko",
        "さ": "sa", "し": "shi", "す": "su", "せ": "se", "そ": "so",
        "た": "ta", "ち": "chi", "つ": "tsu", "て": "te", "と": "to",
        "な": "na", "に": "ni", "ぬ": "nu", "ね": "ne", "の": "no",
        "は": "ha", "ひ": "hi", "ふ": "fu", "へ": "he", "ほ": "ho",
        "ま": "ma", "み": "mi", "む": "mu", "め": "me", "も": "mo",
        "や": "ya", "ゆ": "yu", "よ": "yo",
        "ら": "ra", "り": "ri", "る": "ru", "れ": "re", "ろ": "ro",
        "わ": "wa", "を": "wo", "ん": "n",
        "が": "ga", "ぎ": "gi", "ぐ": "gu", "げ": "ge", "ご": "go",
        "ざ": "za", "じ": "ji", "ず": "zu", "ぜ": "ze", "ぞ": "zo",
        "だ": "da", "ぢ": "ji", "づ": "zu", "で": "de", "ど": "do",
        "ば": "ba", "び": "bi", "ぶ": "bu", "べ": "be", "ぼ": "bo",
        "ぱ": "pa", "ぴ": "pi", "ぷ": "pu", "ぺ": "pe", "ぽ": "po",
        "きゃ": "kya", "きゅ": "kyu", "きょ": "kyo",
        "しゃ": "sha", "しゅ": "shu", "しょ": "sho",
        "ちゃ": "cha", "ちゅ": "chu", "ちょ": "cho",
        "にゃ": "nya", "にゅ": "nyu", "にょ": "nyo",
        "ひゃ": "hya", "ひゅ": "hyu", "ひょ": "hyo",
        "みゃ": "mya", "みゅ": "myu", "みょ": "myo",
        "りゃ": "rya", "りゅ": "ryu", "りょ": "ryo",
        "ぎゃ": "gya", "ぎゅ": "gyu", "ぎょ": "gyo",
        "じゃ": "ja", "じゅ": "ju", "じょ": "jo",
        "びゃ": "bya", "びゅ": "byu", "びょ": "byo",
        "ぴゃ": "pya", "ぴゅ": "pyu", "ぴょ": "pyo",
        "ア": "a", "イ": "i", "ウ": "u", "エ": "e", "オ": "o",
        "カ": "ka", "キ": "ki", "ク": "ku", "ケ": "ke", "コ": "ko",
        "サ": "sa", "シ": "shi", "ス": "su", "セ": "se", "ソ": "so",
        "タ": "ta", "チ": "chi", "ツ": "tsu", "テ": "te", "ト": "to",
        "ナ": "na", "ニ": "ni", "ヌ": "nu", "ネ": "ne", "ノ": "no",
        "ハ": "ha", "ヒ": "hi", "フ": "fu", "ヘ": "he", "ホ": "ho",
        "マ": "ma", "ミ": "mi", "ム": "mu", "メ": "me", "モ": "mo",
        "ヤ": "ya", "ユ": "yu", "ヨ": "yo",
        "ラ": "ra", "リ": "ri", "ル": "ru", "レ": "re", "ロ": "ro",
        "ワ": "wa", "ヲ": "wo", "ン": "n",
        "ガ": "ga", "ギ": "gi", "グ": "gu", "ゲ": "ge", "ゴ": "go",
        "ザ": "za", "ジ": "ji", "ズ": "zu", "ゼ": "ze", "ゾ": "zo",
        "ダ": "da", "ヂ": "ji", "ヅ": "zu", "デ": "de", "ド": "do",
        "バ": "ba", "ビ": "bi", "ブ": "bu", "ベ": "be", "ボ": "bo",
        "パ": "pa", "ピ": "pi", "プ": "pu", "ペ": "pe", "ポ": "po",
        "キャ": "kya", "キュ": "kyu", "キョ": "kyo",
        "シャ": "sha", "シュ": "shu", "ショ": "sho",
        "チャ": "cha", "チュ": "chu", "チョ": "cho",
        "ニャ": "nya", "ニュ": "nyu", "ニョ": "nyo",
        "ヒャ": "hya", "ヒュ": "hyu", "ヒョ": "hyo",
        "ミャ": "mya", "ミュ": "myu", "ミョ": "myo",
        "リャ": "rya", "リュ": "ryu", "リョ": "ryo",
        "ギャ": "gya", "ギュ": "gyu", "ギョ": "gyo",
        "ジャ": "ja", "ジュ": "ju", "ジョ": "jo",
        "ビャ": "bya", "ビュ": "byu", "ビョ": "byo",
        "ピャ": "pya", "ピュ": "pyu", "ピョ": "pyo"
    };

    // Replace all Hiragana/Katakana in the input with corresponding Romaji
    let result = "";
    for (let i = 0; i < input.length; i++) {
        let match = null;

        // Try to match a compound character (e.g., きゃ)
        if (i + 1 < input.length) {
            match = kanaToRomajiMap[input[i] + input[i + 1]];
            if (match) {
                result += match;
                i++; // Skip the next character since it's part of the compound
                continue;
            }
        }

        // Try to match a single character
        match = kanaToRomajiMap[input[i]];
        if (match) {
            result += match;
        } else {
            // If no match, append the original character (e.g., punctuation)
            result += input[i];
        }
    }

    return result;
}