import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/admin',
		name: 'AdminPage',
		component: () => import('@/views/AdminPage'),
	},
	{
		path: '/',
		name: 'Main',
		component: () => import('@/views/MainPage'),
	},
	/*
	{
		path: '/user/:username',
		name: 'UserPage',
		component: UserPage
	}, */
	{
		path: '/my-words',
		name: 'MyWords',
		component: () => import('@/views/MyWords'),
	},
	{
		path: '/favorites',
		name: 'Favorites',
		component: () => import('@/views/FavoritesPage'),
	},
	{
		path: '/course/',
		name: 'CourseListAllPage',
		component: () => import('@/views/CourseListAllPage'),
	},

	{
		path: '/course/create',
		name: 'CourseEditPage',
		component: () => import('@/views/CourseEditPage'),
	},
	// {
	// 	path: '/course/:courseName',
	// 	name: 'CourseShowPage',
	// 	component: CourseShowPage,
	// },
	{
		path: '/course/:courseId',
		name: 'CourseTreePage',
		component: () => import('@/views/CourseTreePage'),
	},
	{
		path: '/course/:courseName/training',
		name: 'CourseTraining',
		component: () => import('@/views/CourseTrainingPage'),
	},
	{
		path: '/course/:courseName/edit',
		name: 'CourseEditPage',
		component: () => import('@/views/CourseEditPage'),
	},
	{
		path: '/:category/:topic',
		name: 'MainCategoryAll',
		component: () => import('@/views/MainPage'),
	},
	{
		path: '/:category',
		name: 'MainCategory',
		component: () => import('@/views/MainPage'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
