import Vue from 'vue';
import PortalVue from 'portal-vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(PortalVue);
Vue.config.productionTip = false;
// i18n is in App.vue

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

// initialize squircles
if ("paintWorklet" in CSS) {
	CSS.paintWorklet.addModule(
		"https://www.unpkg.com/css-houdini-squircle/squircle.min.js"
		//"https://www.unpkg.com/css-houdini-squircle@0.1.3/squircle.min.js"
	);
}
  